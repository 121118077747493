import React, { useContext } from 'react';
import {
  ArbitrarySeriesDto,
  ArbitrarySeriesWithoutData,
  SectionWithoutData,
} from '../sharedTypes';
import SeriesList from './SeriesList';
import AddSeries from './AddSeries';
import DataContext from '../DataContext';

type Props = {
  section: SectionWithoutData;
}

export default function SectionComponent(props: Props): JSX.Element {
  const { section: { name: sectionName, arbitrarySeries } } = props;
  const dataContext = useContext(DataContext);

  function handleDelete(series: ArbitrarySeriesWithoutData) {
    dataContext.deleteSeries({
      section: sectionName,
      slug: series.slug,
    });
  }

  function handleAddArbitrary(dto: ArbitrarySeriesDto) {
    dataContext.addSeries(dto);
  }

  return (
    <>
      <div className="row mt-3 text-capitalize">
        <h1>
          {sectionName}
        </h1>
      </div>
      <div className="row border-bottom pb-3 mt-2">
        <div className="col">
          <AddSeries onAdd={handleAddArbitrary} section={sectionName} />
        </div>
        <div className="col offset-1">
          <h5 className="mb-4">
            Gespeichert in der SQL-DB:
          </h5>
          <SeriesList series={arbitrarySeries} onDelete={handleDelete} />
        </div>
      </div>
    </>
  );
}
