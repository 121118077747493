import React, { useContext } from 'react';
import { assertUnreachable } from '../helper';
import { ApiState } from '../sharedTypes';
import DataContext from '../DataContext';

type Props = {
  apiState: ApiState;
}

function ApiStateComponent(props: Props): JSX.Element {
  const { apiState } = props;
  const { calculateDb } = useContext(DataContext);

  function handleCalculate() {
    calculateDb();
  }

  function getStatus() {
    switch (apiState) {
      case 'OK':
        return (
          <h1 className="text-success">
            Alles OK!
          </h1>
        );
      case 'CALCULATING':
        return (
          <>
            <h1 className="text-warning">
              API offline, Berechnung läuft...
            </h1>
            <p>
              Die API-DB, wird gerade neu berechnet, dies dauert ein par Minuten.
              <br />
              Das Api wird danach wieder verfügbar sein.
            </p>
          </>
        );
      case 'OK_CALCULATION_NEEDED':
        return (
          <>
            <h1 className="text-warning">
              API-DB nicht mehr aktuell!
            </h1>
            <p>
              Das API läuft, es verwendet jedoch noch nicht die neusten Daten.
              <br />
              Mit «DB NEU BERECHNEN» wird das API
              aktualisiert:
            </p>
          </>
        );
      case 'ERROR':
        return (
          <>
            <h1 className="text-danger">
              Fehler, API offline!
            </h1>
            <p>
              Es ist ein Fehler aufgetreten, die API-DB konnte nicht berechnet werden.
              <br />
              Möglicherweise wurden falsche Datenformate hochgeladen. An Tiziano wenden.
            </p>
          </>
        );
      default:
        assertUnreachable(apiState);
        return <></>;
    }
  }

  return (
    <>
      <h5>
        Status:
      </h5>
      {getStatus()}
      <button
        disabled={apiState === 'CALCULATING'}
        className="btn mt-4 btn-outline-warning w-100"
        type="button"
        onClick={handleCalculate}
      >
        DB NEU BERECHNEN
      </button>
      <div className="mt-1">
        <small className="text-white-50">
          Das API wird während ca. 5 Minuten nicht mehr verfügbar sein.
        </small>
      </div>
    </>
  );
}

export default ApiStateComponent;
