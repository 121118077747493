import React, { ChangeEvent, useState } from 'react';
import NumericInput from 'react-numeric-input';
import { ArbitrarySection, ArbitrarySeriesDto, isValidSlug } from '../sharedTypes';
import { parseCustomCSV } from '../validation';
import { assertUnreachable } from '../helper';


type Props = {
  onAdd: (dto: ArbitrarySeriesDto) => void;
  section: ArbitrarySection;
}

type FileState = {
  state: 'NO_FILE';
} | {
  state: 'CHECKING';
  file: string;
} | {
  state: 'OK';
  file: string;
  data: number[];
} | {
  state: 'INVALID';
  error: string;
  file: string;
}

type SlugState = {
  state: 'NO_SLUG';
} | {
  state: 'OK';
  slug: string;
} | {
  state: 'INVALID';
  slug: string;
}

export default function AddSeries(props: Props): JSX.Element {
  const { section } = props;
  const [file, setFile] = useState<FileState>({ state: 'NO_FILE' });
  const [slug, setSlug] = useState<SlugState>({ state: 'NO_SLUG' });
  const [order, setOrder] = useState<number>(10);

  function handleSubmit() {
    if (isSubmitDisabled()) {
      return;
    }
    if (file.state !== 'OK' || slug.state !== 'OK') {
      return;
    }
    const { onAdd } = props;
    onAdd({
      order,
      slug: slug.slug,
      section,
      dataSeries: file.data,
    });
  }

  function handleFileChange(e: ChangeEvent<HTMLInputElement>) {
    const fileList = e.target.files;

    if (!fileList) {
      return;
    }
    const fileObj = fileList[0];
    const fileName = fileObj.name;

    const reader = new FileReader();
    reader.onload = () => {
      try {
        const data = parseCustomCSV(reader.result as string);
        setFile({ state: 'OK', file: fileName, data });
      } catch (err) {
        setFile({ state: 'INVALID', error: err.toString(), file: fileName });
      }
    };

    setFile({ state: 'CHECKING', file: fileName });
    reader.readAsText(fileObj);
  }

  function handleSlugChange(e: ChangeEvent<HTMLInputElement>) {
    const newSlug = e.target.value as unknown as string;
    if (newSlug === '') {
      setSlug({ state: 'NO_SLUG' });
      return;
    }
    if (isValidSlug(newSlug)) {
      setSlug({ state: 'OK', slug: newSlug });
    } else {
      setSlug({ state: 'INVALID', slug: newSlug });
    }
  }

  function isSubmitDisabled() {
    return slug.state !== 'OK' || file.state !== 'OK';
  }

  function fileStateToClass() {
    const { state } = file;
    switch (state) {
      case 'OK':
        return 'is-valid';
      case 'INVALID':
        return 'is-invalid';
      case 'CHECKING':
      case 'NO_FILE':
        return '';
      default:
        assertUnreachable(state);
        return '';
    }
  }

  function slugStateToClass() {
    const { state } = slug;
    switch (state) {
      case 'OK':
        return 'is-valid';
      case 'INVALID':
        return 'is-invalid';
      case 'NO_SLUG':
        return '';
      default:
        assertUnreachable(state);
        return '';
    }
  }

  return (
    <div className="row">
      <h5 className="col-12 mb-3">
        Neue Datenserie hinzufügen
      </h5>

      <div className="col-12">
        <label htmlFor="formFile" className="form-label w-100">
          CSV-Datei (Werte in GW)
          <input
            className={`form-control ${fileStateToClass()}`}
            type="file"
            accept=".csv"
            id="formFile"
            onChange={handleFileChange}
          />
          <div className="valid-feedback">
            Sieht gut aus!
          </div>
          <div className="invalid-feedback">
            {file.state === 'INVALID' ? file.error : 'Nicht Ok.'}
          </div>
        </label>
      </div>

      <div className="col-md-4">
        <label htmlFor="formSlug" className="form-label w-100">
          Identifier (slug)
          <input
            type="text"
            className={`form-control ${slugStateToClass()}`}
            placeholder="beispiel_id"
            id="formSlug"
            value={slug.state === 'NO_SLUG' ? '' : slug.slug}
            onChange={handleSlugChange}
          />
          <div className="valid-feedback">
            Sieht gut aus!
          </div>
          <div className="invalid-feedback">
            Identifier muss mit einem Buchstaben beginnen und darf nur die Zeichen a-Z 0-9 und _
            enthalten.
          </div>
        </label>
      </div>

      <div className="col-md-4">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="orderSlug" className="form-label w-100">
          Reihenfolge
          <NumericInput
            type="number"
            className="form-control"
            id="orderSlug"
            value={order}
            onChange={(maybeNumber) => setOrder(maybeNumber || 0)}
          />
        </label>
      </div>

      <div className="col-md-4 mt-4">
        <button
          onClick={handleSubmit}
          disabled={isSubmitDisabled()}
          type="button"
          className="btn btn-success w-100"
        >
          {file.state === 'CHECKING' ? (
            <>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
              Validierung...
            </>
          ) : 'HOCHLADEN'}
        </button>
      </div>

    </div>
  );
}
