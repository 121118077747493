import React from 'react';
import { ArbitrarySeriesWithoutData } from '../sharedTypes';

type Props = {
  series: ArbitrarySeriesWithoutData;
  onDelete: (series: ArbitrarySeriesWithoutData) => void;
}

export default function SeriesListItem(props: Props): JSX.Element {
  const { series, onDelete } = props;

  function handleDelete() {
    onDelete(series);
  }

  const getArbitraryItem = (arbitrarySeries: ArbitrarySeriesWithoutData) => (
    <>
      <h4 className="m-0 flex-grow-1">{arbitrarySeries.slug}</h4>
      <div className="d-flex text-nowrap">
        <p className="m-0">
          {`Reihenfolge: ${arbitrarySeries.order}`}
        </p>
      </div>
      <button onClick={handleDelete} className="btn btn-danger ml-4" type="button">
        LÖSCHEN
      </button>
    </>
  );

  return (
    <li
      className="list-group-item border-light list-group-item-success d-flex align-items-center justify-content-end bg-dark text-light"
    >
      {getArbitraryItem(series)}
    </li>
  );
}

