import { AdminStuffDto, ArbitrarySeriesDto, DeleteArbitrarySeriesDto } from './sharedTypes';

const API = '/admin-api';
const ADMIN_STUFF = '/get/on-demand';
const UPLOAD_ARBITRARY = '/upload/arbitrary';
const DELETE_ARBITRARY = '/delete/arbitrary';
const CALCULATE_DB = '/calculate-db';

export async function fetchState(): Promise<AdminStuffDto> {
  const request = `${API}${ADMIN_STUFF}`;
  return send(request);
}

function send<T>(request: string, options?: RequestInit): Promise<T> {
  return new Promise((resolve, reject) => {
    fetch(request, options)
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        }
        const text = await response.text();
        throw new Error(`${response.status} ${text.length < 50 ? `(${text})` : ''}`);
      })
      .then((body) => {
        resolve(body);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export async function calculateDb(): Promise<AdminStuffDto> {
  const request = `${API}${CALCULATE_DB}`;
  const options: RequestInit = {
    method: 'POST',
  };
  return send(request, options);
}

export async function uploadSeries(dto: ArbitrarySeriesDto): Promise<AdminStuffDto> {
  const request = `${API}${UPLOAD_ARBITRARY}`;
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(dto),
  };
  return send(request, options);
}

export async function deleteSeries(dto: DeleteArbitrarySeriesDto): Promise<AdminStuffDto> {
  const request = `${API}${DELETE_ARBITRARY}`;
  const options: RequestInit = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(dto),
  };
  return send(request, options);
}

