import React, { useEffect, useState } from 'react';
import './App.css';
import { AdminStuffDto, ArbitrarySeriesDto, DeleteArbitrarySeriesDto } from './sharedTypes';
import Sections from './components/Sections';
import ApiStateComponent from './components/ApiStateComponent';
import {
  calculateDb, deleteSeries, fetchState, uploadSeries,
} from './api';
import DataContext, { ContextType } from './DataContext';

function App(): JSX.Element {
  const [state, setState] = useState<ContextType>({
    data: { status: 'UNINITIALIZED' },
    addSeries: handleAddSeries,
    deleteSeries: handleDeleteSeries,
    calculateDb: handleCalculateDb,
  });

  function handleAddSeries(dto: ArbitrarySeriesDto) {
    uploadSeries(dto)
      .then(updateContextOnSuccess)
      .catch(updateContextOnError);
  }

  function handleCalculateDb() {
    calculateDb()
      .then(updateContextOnSuccess)
      .catch(updateContextOnError);
  }

  function handleDeleteSeries(dto: DeleteArbitrarySeriesDto) {
    deleteSeries(dto)
      .then(updateContextOnSuccess)
      .catch(updateContextOnError);
  }

  function updateContextOnError(e: Error) {
    setState({
      ...state,
      data: { status: 'ERROR', error: e.toString() },
    });
  }

  function updateContextOnSuccess(resultDto: AdminStuffDto) {
    setState({
      ...state,
      data: { ...resultDto, status: 'OK' },
    });
  }

  useEffect(() => {
    fetchState()
      .then(updateContextOnSuccess)
      .catch(updateContextOnError);
  }, []);

  return (
    <DataContext.Provider value={state}>
      <div className="App">
        <Content />
      </div>
    </DataContext.Provider>
  );

  function Content(): JSX.Element {
    const { data } = state;
    if (data.status === 'UNINITIALIZED' || data.status === 'ERROR') {
      return (
        <>
          <header className="App-header">
            <h1>
              Powercheck Admin Tool
            </h1>
          </header>
          <main className="App-main">
            <h5>
              {data.status === 'UNINITIALIZED' && 'Verbinden...'}
              {data.status === 'ERROR' && `Fehler: ${data.error}`}
            </h5>
          </main>
        </>
      );
    }

    const { sections, apiState } = data;

    return (
      <>
        <div className="App-Left">
          <ApiStateComponent apiState={apiState} />
        </div>
        <div className="App-Right bg-light">
          <header className="App-header">
            <h1>
              Powercheck Admin Tool v2
            </h1>
          </header>
          <main className="App-main">
            {apiState !== 'CALCULATING'
              ? <Sections sections={sections} />
              : (
                <p>
                  Das Admin Tool ist nicht verfügbar während die API neu berechnet wird.
                  Dies Dauert ca. 10 Minuten.
                  <br />
                  Die Seite lädt nicht automatisch neu!
                </p>
              )}
          </main>
        </div>
      </>
    );
  }
}

export default App;
