import React from 'react';
import SectionComponent from './SectionComponent';
import { SectionWithoutData } from '../sharedTypes';

type Props = {
  sections: SectionWithoutData[];
}

export default function Sections(props: Props): JSX.Element {
  const { sections } = props;

  return (
    <div className="container">
      {sections.map((section) => <SectionComponent section={section} />)}
    </div>
  );
}
