import { createContext } from 'react';
import {
  ApiState,
  ArbitrarySeriesDto,
  DeleteArbitrarySeriesDto,
  SectionWithoutData,
} from './sharedTypes';

export type ContextType = {
  data: State;
  addSeries: (dto: ArbitrarySeriesDto) => void
  deleteSeries: (dto: DeleteArbitrarySeriesDto) => void
  calculateDb: () => void
}

type State = {
  status: 'OK';
  sections: SectionWithoutData[];
  apiState: ApiState;
} | {
  status: 'ERROR';
  error: string;
} | {
  status: 'UNINITIALIZED';
}

const DataContext = createContext<ContextType>({
  data: { status: 'UNINITIALIZED' },
  addSeries: () => {
    // do nothing
  },
  deleteSeries: () => {
    // do nothing
  },
  calculateDb: () => {
    // do nothing
  },
});

export default DataContext;
