import _ from 'lodash';
import Papa from 'papaparse';
import { NR_OF_VALUES } from './constants';

const verifyCustomDataArray = (data: number[]) => {
  if (data.length !== NR_OF_VALUES) {
    throw new Error(`Expected ${NR_OF_VALUES} Values but found ${data.length} instead.`);
  }
};

const firstColumnOf = (array: string[][]) => array.map((row) => _.head(row));

const convertCSVStringTo2dArray = (csvString: string): string[][] => (
  Papa.parse(csvString.trim()).data as string[][]
);

const parseFloatOrException = (value: string | undefined): number => {
  if (value === undefined) {
    throw new Error('can\'t parse empty field');
  }
  return parseFloat(value);
};

export const parseCustomCSV = (csvString: string): number[] => {
  const rawArray = convertCSVStringTo2dArray(csvString);
  const customData = firstColumnOf(rawArray).map((value) => parseFloatOrException(value));
  verifyCustomDataArray(customData);
  return customData;
};
