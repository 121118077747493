import React from 'react';
import { ArbitrarySeriesWithoutData } from '../sharedTypes';
import SeriesListItem from './SeriesListItem';

type Props = {
  series: ArbitrarySeriesWithoutData[];
  onDelete: (series: ArbitrarySeriesWithoutData) => void;
}

export default function SeriesList(props: Props): JSX.Element {
  const { onDelete } = props;

  const { series: rawSeries } = props;
  const series = rawSeries.sort((a, b) => a.order - b.order);

  return (
    <ul className="list-group">
      {series.length > 0
        ? series.map((s: ArbitrarySeriesWithoutData) => (
          <SeriesListItem series={s} onDelete={onDelete} />
        ))
        : (
          <li className="list-group-item text-center p-5">
            <small>
              Keine Datenserien gespeichert
            </small>
          </li>
        )}
    </ul>
  );
}
